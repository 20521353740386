import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: `'Montserrat', regular`,
  },
  
});

export default theme;
